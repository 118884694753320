import React, { useState } from 'react';

const WorkspaceCard = ({ title, imgSrc, requiresPeople, placeholder, tooltipText, people, handleInputChange, handleAdd }) => {
    const [currentTooltip, setCurrentTooltip] = useState('');
  
    return (
      <div className="mr-2 w-full md:flex-1 flex-1 mb-6">
        <figure className="text-center relative max-w-max mx-auto">
          <figcaption className="mb-2">{title}</figcaption>
          <img src={imgSrc} alt={title} />
          <span className="absolute top-10 right-2 bg-red-600 text-white rounded-full w-6 h-6 flex items-center justify-center cursor-pointer"
            onMouseEnter={() => setCurrentTooltip(title)}
            onMouseLeave={() => setCurrentTooltip('')}
          >
            ?
            {currentTooltip === title && (
              <div className="absolute top-full right-0 text-xs bg-black text-white p-2 rounded z-10 w-48">
                {tooltipText}
              </div>
            )}
          </span>
        </figure>
        <div className="mt-3 max-w-fit mx-auto">
          {requiresPeople && (
            <div>
              <input
                type="text"
                placeholder={placeholder}
                value={people[title]}
                onChange={(e) => handleInputChange(title, e.target.value)}
                className="rounded-lg w-9 h-9 border border-cyan-900 text-center text-cyan-900"
              />
              <span className="ml-2">people</span>
            </div>
          )}
          <button 
            onClick={() => handleAdd(title)}
            className="block w-20 mx-auto mt-3 px-2 py-1 bg-red-600 rounded-3xl text-white cursor-pointer hover:bg-white border-red-600 border hover:text-red-600 transition-colors duration-300 ease-in-out"
          >
            Add <i className="fas fa-plus"></i>
          </button>
        </div>
      </div>
    );
};

export default WorkspaceCard;
