import MeetingRoomImg from '../svg/meetingroom.svg';
import ConferenceRoomImg from '../svg/conferenceroom.svg';
import BoardRoomImg from '../svg/boardroom.svg';

const meetingspaceData = [
  {
    title: 'Meeting Room',
    imgSrc: MeetingRoomImg,
    requiresPeople: true,
    placeholder: '8',
    tooltipText: 'Designed for smaller gatherings. Ideal for team huddles and one-on-one meetings.',
  },
  {
    title: 'Conference Room',
    imgSrc: ConferenceRoomImg,
    requiresPeople: true,
    placeholder: '16',
    tooltipText: 'Built to accommodate larger groups. Perfect for formal presentations and company-wide meetings.',
  },
  {
    title: 'Boardroom',
    imgSrc: BoardRoomImg,
    requiresPeople: true,
    placeholder: '12',
    tooltipText: 'High-end meeting space. Well-suited for executive meetings and important business discussions.',
  },
];

export default meetingspaceData;