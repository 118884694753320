import React, { useState } from 'react';

function ChatGPTPage() {
  const [chatHistory, setChatHistory] = useState([]);
  const [userInput, setUserInput] = useState('');

  // Simulate ChatGPT acting as an office advisor
  const chatGPTResponse = (userMessage) => {
    // Here you can add more complex logic or even API calls to generate responses
    return `Office Advisor GPT: I see you mentioned ${userMessage}. Here's some advice...`;
  };

  const handleUserInput = (e) => {
    setUserInput(e.target.value);
  };

  const handleSend = () => {
    setChatHistory([...chatHistory, { text: userInput, sender: 'User' }]);

    const gptResponse = chatGPTResponse(userInput);
    setChatHistory(prev => [...prev, { text: gptResponse, sender: 'GPT' }]);
    setUserInput('');
  };

  return (
    <div>
      <h2>Office Advisor Chat</h2>
      <div>
        {chatHistory.map((message, index) => (
          <p key={index}><strong>{message.sender}:</strong> {message.text}</p>
        ))}
      </div>
      <div>
        <input
          type="text"
          value={userInput}
          onChange={handleUserInput}
          placeholder="Type your message here"
        />
        <button onClick={handleSend}>Send</button>
      </div>
    </div>
  );
}

export default ChatGPTPage;
