import React from 'react'
import { Link } from 'react-scroll';
import ProcessSvg from '../svg/process.svg'

const Steps = () => {
  return (
    <div className='bg-gray-100 py-6' id='how-it-works'>
        <div className="container mx-auto max-w-7xl">
            <h2 className='text-3xl font-bold text-center'>How it works?</h2>
            <h3 className='text-center mt-1 mb-4 text-lg'>The solution to find the best office space.</h3>
            <img src={ProcessSvg} alt="" />
            <div className="flex items-start">
                <p className='text-center w-1/5 ml-4 mt-3'>Calculate your office needs</p>
                <p className="w-1/5"></p>
                <p className='text-center w-1/5 mt-3'>Consult with our AI assistant</p>
                <p className="w-1/5"></p>
                <p className='text-center w-1/5 mt-3'>Get in touch with the available office spaces</p>
            </div>

            <Link 
              to="calculator"
              smooth={true}
              duration={500}
              className='bg-red-600 text-white py-2 px-5 mt-5 mx-auto block max-w-max rounded-lg cursor-pointer'
            >
              See more <i className="fas fa-caret-down"></i>
            </Link>
        </div>
    </div>
  )
}

export default Steps