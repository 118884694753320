import React from 'react';
import HeroImg from '../svg/hero.svg';
import { Link } from 'react-scroll';

const Hero = () => {
  return (
    <div className="container mx-auto max-w-7xl px-4 sm:px-0">
      <div className='flex flex-col sm:flex-row mt-5 sm:mt-5 items-center'>
          <div className='ml-2 w-full sm:w-2/5 pt-6 sm:pt-0'>
            <h2 className='text-cyan-900 font-extrabold text-3xl sm:text-4xl'>The First AI-powered<br></br>Office Search Portal</h2>
            <p className='mt-3'>Leveraging the power of AI to redefine your office search experience. Explore, Compare, and Secure.</p>
            <Link
              to="how-it-works"
              smooth={true}
              duration={500}
              className='bg-red-600 px-4 py-2 text-white mt-4 inline-block rounded-lg cursor-pointer'
            >
              See how it works <i className="fa-solid fa-chevron-right"></i>
            </Link>
          </div>
          <img src={HeroImg} alt="" className='w-4/5 sm:w-3/5 mt-6 sm:mt-0' />
      </div>
    </div>
  );
}

export default Hero;
