import React, { useState } from 'react';
import { animated, useSpring } from 'react-spring';
import WorkspaceCard from './WorkspaceCard';

const Collapsible = ({ addSpace, workspaceData, defaultOpen = false, title }) => {
  const [isOpenWorkspaces, setIsOpenWorkspaces] = useState(defaultOpen);
  const slideAnimationWorkspaces = useSpring({
    maxHeight: isOpenWorkspaces ? '1500px' : '0px',
    opacity: isOpenWorkspaces ? 1 : 0,
    overflow: 'hidden',
  });

  const [people, setPeople] = useState({
    'Open Office': '',
    'Private Office': '',
    'Collaborative Space': '',
    'Phone Booth': ''
  });

  const handleAdd = (type) => {
    if (['Kitchen', 'Storage Room', 'Reception', 'Server Room'].includes(type)) {
      addSpace(type, null);
      return;
    }

    if (people[type] === '' || isNaN(people[type])) {
      alert('Please enter a valid number of people.');
      return;
    }
    addSpace(type, people[type]);
    setPeople({ ...people, [type]: '' });
  };

  const handleInputChange = (type, value) => {
    setPeople({ ...people, [type]: value });
  };

  return (
    <div>
      <div
        className="flex justify-between items-center cursor-pointer border-b border-cyan-900 pb-3 mt-3"
        onClick={() => setIsOpenWorkspaces(!isOpenWorkspaces)}
      >
        <h2 className="text-xl font-bold">{title}</h2>
        <span className="text-xl font-bold">
          {isOpenWorkspaces ? '-' : '+'}
        </span>
      </div>
      <animated.div style={slideAnimationWorkspaces} className="mt-4 grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-4">
        {workspaceData.map((workspace) => (
          <WorkspaceCard
            key={workspace.title}
            title={workspace.title}
            imgSrc={workspace.imgSrc}
            requiresPeople={workspace.requiresPeople}
            placeholder={workspace.placeholder}
            tooltipText={workspace.tooltipText}
            people={people}
            handleInputChange={handleInputChange}
            handleAdd={handleAdd}
          />
        ))}
      </animated.div>
    </div>
  );
};

export default Collapsible;
