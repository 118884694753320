import ReceptionImg from '../svg/reception.svg';
import KitchenImg from '../svg/kitchen.svg';
import StorageRoomImg from '../svg/storageroom.svg';
import ServerRoomImg from '../svg/serverroom.svg';

const supportspaceData = [
  {
    title: 'Reception',
    imgSrc: ReceptionImg,
    requiresPeople: false,
    placeholder: '',
    tooltipText: 'The first point of contact for visitors. Often includes a waiting area and front desk.',
  },
  {
    title: 'Kitchen',
    imgSrc: KitchenImg,
    requiresPeople: false,
    placeholder: '',
    tooltipText: 'Where coffee is brewed, snacks disappear, and the best office gossip is cooked up!',
  },
  {
    title: 'Storage Room',
    imgSrc: StorageRoomImg,
    requiresPeople: false,
    placeholder: '',
    tooltipText: 'Used for storing supplies, equipment, and archived documents.',
  },
  {
    title: 'Server Room',
    imgSrc: ServerRoomImg,
    requiresPeople: false,
    placeholder: '',
    tooltipText: 'A climate-controlled room used to house servers and related equipment.',
  },
];

export default supportspaceData;