import React from 'react'
import Divider2Svg from '../svg/divider2.svg'

const Divider2 = () => {
  return (
    <div className='mt-6'>
        <img src={Divider2Svg} alt="" className={"w-full"} />
    </div>
  )
}

export default Divider2