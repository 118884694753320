import OpenOfficeImg from '../svg/openoffice.svg';
import PrivateOfficeImg from '../svg/privateoffice.svg';
import CollaborativeSpaceImg from '../svg/collaborationspace.svg';
import PhoneBoothImg from '../svg/phonebooth.svg';

const workspaceData = [
  {
    title: 'Open Office',
    imgSrc: OpenOfficeImg,
    requiresPeople: true,
    placeholder: '6',
    tooltipText: 'Open Offices offer a spacious environment for a more free-form and flexible work setup. These spaces are ideal for teams that need room for varying workstations, without the confines of walls or partitions.',
  },
  {
    title: 'Private Office',
    imgSrc: PrivateOfficeImg,
    requiresPeople: true,
    placeholder: '2',
    tooltipText: 'Private Offices are enclosed spaces that provide a quiet and focused environment. Perfect for individuals or small teams who require isolation for deep work or sensitive tasks.',
  },
  {
    title: 'Collaborative Space',
    imgSrc: CollaborativeSpaceImg,
    requiresPeople: true,
    placeholder: '8',
    tooltipText: 'Collaborative Spaces are designed for teamwork and group activities. These areas typically feature a variety of seating arrangements and tools to facilitate brainstorming, discussions, and creative processes.',
  },
  {
    title: 'Phone Booth',
    imgSrc: PhoneBoothImg,
    requiresPeople: true,
    placeholder: '1',
    tooltipText: 'Phone Booths are compact, enclosed spaces designed for making private phone calls or video conferencing. They offer a quiet refuge without occupying a full room, making them efficient for limited-space offices.',
  },
];

export default workspaceData;