import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Collapsible from "./Collapsible";
import workspaceData from '../data/workspaceData';
import supportspaceData from '../data/supportspaceData';
import meetingspaceData from '../data/meetingspaceData';

const Calculator = () => {
  const [spaces, setSpaces] = useState([]); // To store the added spaces
  const [selectedCirculation, setSelectedCirculation] = useState(12);
  const [calculatedSize, setCalculatedSize] = useState(null);
  const [isEditingSize, setIsEditingSize] = useState(false);
  const [tempSize, setTempSize] = useState({ min: null, max: null });
  const percentages = [12, 15, 20]; // The available percentages
  const navigate = useNavigate();

  const handleGetStarted = () => {
    navigate('/consult', { state: calculateSize });
  };

  const addSpace = (type, people = null) => {
    const newSpace = { type, people };
    setSpaces([...spaces, newSpace]);
  };

  const removeSpace = (type, people) => {
    let found = false; // Track if we've already removed one
    setSpaces(spaces.filter(space => {
      if (!found && space.type === type && space.people === people) {
        found = true; // Mark as found so we only remove one
        return false; // Remove this one
      }
      return true; // Keep all the others
    }));
  };

  const sortedSpaces = [...spaces].sort((a, b) => {
    if (a.type === b.type) {
      return b.people - a.people; // Sort by the number of people in descending order if the type is the same
    }
    return a.type.localeCompare(b.type); // Otherwise, sort by the type in alphabetical order
  });

  const calculateSize = () => {
    setIsEditingSize(false);
    let minSize = 0;
    let maxSize = 0;
  
    spaces.forEach((space) => {
      const type = space.type;
      const people = space.people ? parseInt(space.people, 10) : null;
      let extraSpace = 0; // Extra space for shared amenities in a private office
  
      switch (type) {
        case 'Open Office':
          minSize += people * 6;
          maxSize += people * 8;
          break;
        case 'Private Office':
          if (people === 1) extraSpace = 5;
          minSize += (people * 10) + extraSpace;
          maxSize += (people * 15) + extraSpace;
          break;
        case 'Phone Booth':
          minSize += people * 1.5;
          maxSize += people * 2;
          break;
        case 'Collaborative Space':
          minSize += people * 5;
          maxSize += people * 10;
          break;
        case 'Meeting Room':
          minSize += people * 4;
          maxSize += people * 6;
          break;
        case 'Conference Room':
          minSize += people * 3;
          maxSize += people * 5;
          break;
        case 'Boardroom':
          minSize += people * 5;
          maxSize += people * 7;
          break;
        case 'Kitchen':
          minSize += 10;
          maxSize += 20;
          break;
        case 'Reception':
          minSize += 10;
          maxSize += 15;
          break;
        case 'Storage Room':
          minSize += 5;
          maxSize += 10;
          break;
        case 'Server Room':
          minSize += 8;
          maxSize += 12;
          break;
        default:
          break;
      }
    });
  
    // Adding circulation space
    const circulationFactor = 1 + selectedCirculation / 100;
    minSize = Math.ceil(minSize * circulationFactor);
    maxSize = Math.ceil(maxSize * circulationFactor);
    
    console.log("Calculated Size: ", minSize, maxSize); // Debug log
  
    setCalculatedSize([minSize, maxSize]);
  };

  const handleSaveSize = () => {
    setCalculatedSize([tempSize.min, tempSize.max]);
    setIsEditingSize(false);
  };   

  return (
    <div className="container mx-auto max-w-7xl mt-5" id="calculator">
        <h2 className='text-3xl font-bold text-center'>Office calculator</h2>
        <h3 className='text-center mt-1 mb-4 text-lg'>Your new office design begins here.</h3>
        
        <div className="md:flex">
          <div className="md:w-3/4 w-full flex md:mr-12 md:pr-0 pr-16 justify-center">
            <div className="">
              <div className="text-2xl bg-red-600 text-white px-4 py-2 mr-3 rounded-full">1.</div>
              <div className="border-l border-cyan-900 border-l-2 ml-5 border-dashed h-full">
                
              </div>
            </div>
            <div>
              <h2 className="text-2xl font-bold mt-2 text-cyan-900">Size & Style</h2>
              <p>Find out the ideal office space for you.</p>
              <Collapsible addSpace={addSpace} workspaceData={workspaceData} defaultOpen={true} title="Workspaces" />
              <Collapsible addSpace={addSpace} workspaceData={meetingspaceData} title="Meeting Spaces" />
              <Collapsible addSpace={addSpace} workspaceData={supportspaceData} title="Support Spaces" />
            </div>
          </div>
          <div className="md:w-1/4">
            <div className="px-5 py-3 bg-cyan-900">
              <h2 className="text-2xl font-bold text-white mb-2 mt-1">Summary</h2>
              <hr className='mb-2' />
              {sortedSpaces.map((space, index) => {
                const isLastOfType = index === sortedSpaces.length - 1 || sortedSpaces[index + 1].type !== space.type;
                return (
                  <div 
                    className={`flex justify-between items-center mt-1 ${isLastOfType ? 'mb-3' : ''}`} 
                    key={index}
                  >
                    <span className="text-white">
                      <span className="uppercase text-sm inline-block mr-1">{space.type}</span> {space.people !== null && <i className="fas fa-users text-xl"></i>} {space.people}
                    </span>
                    <button
                      className="rounded-full w-6 h-6 flex items-center justify-center bg-white"
                      onClick={() => removeSpace(space.type, space.people)}
                    >
                      <span className="text-cyan-900 text-center" style={{ fontSize: '1rem', position: 'relative', 'bottom': 1 }}>x</span>
                    </button>
                  </div>
                );
              })}
              {spaces.length > 0 && <hr className='mt-3' />}
              <p className="text-xs mt-3 text-white">Add a circulation percentage which represents the amount of space allocated to corridors and circulation paths.</p>
              <div className="flex justify-around mt-3">
                {percentages.map((percentage, index) => (
                  <button 
                    key={index} 
                    className={`rounded-xl w-10 h-10 flex items-center justify-center text-cyan-900 ${selectedCirculation === percentage ? 'bg-white' : 'bg-white bg-opacity-50'}`}
                    onClick={() => setSelectedCirculation(percentage)}
                  >
                    {percentage}%
                  </button>
                ))}
              </div>
              <button 
                className="mt-4 mb-2 px-5 py-2 rounded-3xl bg-white text-cyan-900 min-w-full font-bold text-lg" 
                onClick={calculateSize}
              >
                Calculate <i className="fas fa-chevron-right"></i>
              </button>
              <p 
                className="mt-1 text-white cursor-pointer text-sm text-center hover:underline"
                onClick={() => setIsEditingSize(true)}
              >
                I already know my size requirements
              </p>
            </div>
            {(calculatedSize || isEditingSize) && (
              <div className="bg-red-600 px-5 py-3 mt-4 text-white">
                <h3 className="text-xl font-bold text-white mb-2 mt-1">Your ideal office size</h3>
                <hr />
                <div className="mt-3 flex items-center">
                  {isEditingSize ? (
                    <>
                      <input 
                        type="number" 
                        value={tempSize.min} 
                        onChange={(e) => setTempSize({ ...tempSize, min: e.target.value })} 
                        placeholder="100" 
                        className="mr-2 w-16 text-cyan-900 rounded-md px-2 py-2 text-sm"
                      />
                      <span>-</span>
                      <input 
                        type="number" 
                        value={tempSize.max} 
                        onChange={(e) => setTempSize({ ...tempSize, max: e.target.value })} 
                        placeholder="200"
                        className="ml-2 w-16 text-cyan-900 rounded-md px-2 py-2 text-sm"
                      />
                      <button onClick={handleSaveSize} className="ml-4 border px-2 py-1 hover:bg-white hover:text-red-600">
                      <i className="fas fa-check"></i> Save
                      </button>
                    </>
                  ) : (
                    <>
                      <p>
                        {calculatedSize[0]} - {calculatedSize[1]} m<sup>2</sup>
                      </p>
                      <button onClick={() => setIsEditingSize(true)} className="ml-4 px-2 py-1">
                        <i className="fas fa-pen-to-square"></i> Modify
                      </button>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="md:flex mt-6">
          <div className="w-full flex md:mr-12 md:pr-0 pr-16">
            <div className="">
              <div className="text-2xl bg-red-600 text-white px-4 py-2 mr-3 rounded-full">2.</div>
              <div className="border-l border-cyan-900 border-l-2 ml-5 border-dashed h-full">
                
              </div>
            </div>
            <div>
              <h2 className="text-2xl font-bold mt-2 text-cyan-900">Location and time frame</h2>
              <p>Choose your preferred location and lease term</p>
            </div>
          </div>
        </div>

        <div className="md:flex mt-6">
          <div className="w-full flex md:mr-12 md:pr-0 pr-16">
            <div className="">
              <div className="text-2xl bg-red-600 text-white px-4 py-2 mr-3 rounded-full">3.</div>
              <div className="border-l border-cyan-900 border-l-2 ml-5 border-dashed h-full">
                
              </div>
            </div>
            <div>
              <h2 className="text-2xl font-bold mt-2 text-cyan-900">Budget & Contact Information</h2>
              <p>You will receive offers on the contact details given below</p>
            </div>
          </div>
        </div>

        <div className="md:flex mt-6">
          <div className="w-full flex md:mr-12 md:pr-0 pr-16">
            <div className="">
              <div className="text-xl w-12 h-12 bg-red-600 text-white px-4 py-2 mr-3 rounded-full"><i className="fas fa-check relative" style={{top: 3}}></i></div>
            </div>
            <div>
              <h2 className="text-2xl font-bold mt-2 text-cyan-900">We are all done!</h2>
              <p className='mb-3'>Time to evaluate your needs!</p>
              <button
                onClick={handleGetStarted}
                className='bg-red-600 rounded-full px-3 py-2 text-white'
              >
                Let's get started <i className="fas fa-chevron-right"></i>
              </button>
            </div>
          </div>
        </div>
        
    </div>
  )
}

export default Calculator