import React from 'react'
import Header from './components/Header'
import Hero from './components/Hero'
import Steps from './components/Steps'
import Divider from './components/Divider'
import Divider2 from './components/Divider2'
import Calculator from './components/Calculator'

const Home = () => {
    return (
        <div className='app'>
            <Header />
            <Hero />
            <Steps />
            <Divider />
            <Calculator />
            <Divider2 />
        </div>
    )
}

export default Home