import { useState } from 'react';
import { Link } from 'react-scroll';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="container mx-auto max-w-7xl">
      <header className="mt-8 mx-3 flex flex-wrap items-center">
        <div className="w-full md:w-1/3 flex justify-between md:justify-start items-center">
          <h1 className="logo text-3xl font-bold text-red-600">OfficeFinder<span className="text-cyan-900">.ai</span></h1>
          {/* Hamburger menu icon */}
          <button className="md:hidden px-2 py-1" onClick={toggleMenu}>
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path className="text-red-600" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
            </svg>
          </button>
        </div>
        {/* Navigation */}
        <nav className={`w-full md:w-2/3 ${isOpen ? 'block' : 'hidden'} md:block`}>
          <ul className={`flex flex-col ${isOpen ? 'items-center' : ''} md:flex-row md:justify-end`}>
            <li className={`mr-6 my-2 ${isOpen ? 'md:my-0 mt-6 md:mt-0' : 'md:my-0'}`}>
              <Link
                to="how-it-works"
                smooth={true}
                duration={500}
                className="text-gray-800 hover:text-cyan-900 cursor-pointer"
              >
                How it works?
              </Link>
            </li>
            <li className="mr-6 my-2 md:my-0">
              <a className="text-gray-800 hover:text-cyan-900" href="mailto:info@officefinder.ai">Contact</a>
            </li>
            <li className="mr-6 my-2 md:my-0">
              <Link
                to="calculator"
                smooth={true}
                duration={500}
                className="text-white bg-red-600 px-4 py-2 rounded-md cursor-pointer"
              >Find your next office</Link>
            </li>
          </ul>
        </nav>
      </header>
    </div>
  );
};

export default Header;
