import React from 'react'
import DividerSvg from '../svg/divider.svg'

const Divider = () => {
  return (
    <div className='bg-gray-100'>
        <img src={DividerSvg} alt="" className={"w-full"} />
    </div>
  )
}

export default Divider